import React from "react";

function PrivacyPolicy() {
    const handleConsentRevoke = () => {
        // Implement revocation logic here
        alert("You can revoke your consent by adjusting your cookie preferences.");
    };

    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>
                Effective Date: [Insert Date]
            </p>
            <p>
                Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our application, which integrates with Google AdMob for personalized and non-personalized advertisements. This Privacy Policy complies with the General Data Protection Regulation (GDPR) for users in the European Economic Area (EEA), the UK, and Switzerland.
            </p>

            <h2>1. Information We Collect</h2>
            <ul>
                <li>
                    <strong>Personal Data:</strong> Only if you provide consent, we may collect data such as device identifiers, IP addresses, and advertising IDs for the purpose of personalizing advertisements.
                </li>
                <li>
                    <strong>Non-Personal Data:</strong> We may collect non-identifiable data such as app usage, device type, and language settings.
                </li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>The information collected is used for:</p>
            <ul>
                <li>
                    <strong>Advertising and Analytics:</strong> Displaying personalized or non-personalized ads via Google AdMob and improving ad performance.
                </li>
                <li>
                    <strong>Legal Compliance:</strong> Ensuring compliance with GDPR and other regulations.
                </li>
                <li>
                    <strong>Application Functionality:</strong> Enhancing user experience and fixing app-related issues.
                </li>
            </ul>

            <h2>3. Third-Party Services</h2>
            <p>
                We use <strong>Google AdMob</strong> to serve advertisements within the application. Google AdMob may collect and use your information as per its own Privacy Policy. Learn more:{" "}
                <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Google Privacy Policy
                </a>
            </p>
            <p>
                You can control whether personalized ads are shown by adjusting your privacy settings within the app or opting out of ad personalization through your device settings.
            </p>

            <h2>4. Consent and Revocation</h2>
            <ul>
                <li>
                    <strong>Obtaining Consent:</strong> Before we collect personal data, we request your consent through a clear prompt when you use the app.
                </li>
                <li>
                    <strong>Revoking Consent:</strong> You can revoke your consent by accessing the privacy settings in the app or contacting us at [your email address]. Alternatively, disable ad personalization in your device settings.
                </li>
            </ul>

            <h2>5. Data Storage and Retention</h2>
            <p>
                We only retain your data as long as necessary for the purposes stated in this policy. Non-personal data may be retained for analytics and functionality purposes.
            </p>

            <h2>6. Your Rights</h2>
            <p>Under GDPR, you have the following rights:</p>
            <ul>
                <li>Access to your personal data.</li>
                <li>Request correction or deletion of your data.</li>
                <li>Restrict or object to data processing.</li>
                <li>
                    File a complaint with your local Data Protection Authority if you believe your rights have been violated.
                </li>
            </ul>

            <h2>7. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy or wish to exercise your GDPR rights, please contact us at:
            </p>
            <ul>
                <li>
                    <strong>Email:</strong> [Your Contact Email]
                </li>
                <li>
                    <strong>Address:</strong> [Your Company Address]
                </li>
            </ul>

            <h2>8. Changes to this Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We recommend reviewing this page periodically.
            </p>

            <p>
                By using our services, you consent to the use of cookies and the collection of data as described. If you'd like to revoke your consent, please use the button below or adjust your cookie preferences in your browser settings.
            </p>
            <button onClick={handleConsentRevoke}>Revoke Consent</button>
        </div>
    );
}

export default PrivacyPolicy;
